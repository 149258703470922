import center from '@turf/center';
import L from 'leaflet';
import One from '../NumberIcons/One.png'
import Two from '../NumberIcons/Two.png'
import Three from '../NumberIcons/Three.png'
import Four from '../NumberIcons/Four.png'
import Five from '../NumberIcons/Five.png'
import Six from '../NumberIcons/Six.png'
import Seven from '../NumberIcons/Seven.png'
import Eight from '../NumberIcons/Eight.png'
import Nine from '../NumberIcons/Nine.png'
import Ten from '../NumberIcons/Ten.png'

export function getColor(d) {
  return d > 2500
    ? '#FF9800'
    : d > 2000
      ? '#E31A1C'
      : d > 1500
        ? '#FD8D3C'
        : d > 1000
          ? '#FEB24C'
          : d > 500
            ? '#FED976'
            : '#FFEDA0';
}

export function getCenterOfGeoJson(geoJson) {
  return center(geoJson).geometry.coordinates.reverse();
}

export function layersUtils(geoJsonRef, mapRef) {
  function highlightOnClick(e) {
    const layer = e.target;

    layer.setStyle({
      weight: 2,
      color: '#f90303',
      dashArray: '',
      fillOpacity: 0.7,
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  function resetHighlight(e) {
    geoJsonRef.current.leafletElement.resetStyle(e.target);
  }

  function zoomToFeature(e) {
    mapRef.current.leafletElement.fitBounds(e.target.getBounds());
  }

  return { highlightOnClick, resetHighlight, zoomToFeature };
}

export const getNumberIcon = (val) => {
  const OneIcon = new L.Icon({
    iconUrl: One,
    iconSize: [30, 30], // size of the icon
  })

  const TwoIcon = new L.Icon({
    iconUrl: Two,
    iconSize: [30, 30], // size of the icon
  })
  const ThreeIcon = new L.Icon({
    iconUrl: Three,
    iconSize: [30, 30], // size of the icon
  })
  const FourIcon = new L.Icon({
    iconUrl: Four,
    iconSize: [30, 30], // size of the icon
  })
  const FiveIcon = new L.Icon({
    iconUrl: Five,
    iconSize: [30, 30], // size of the icon
  })
  const SixIcon = new L.Icon({
    iconUrl: Six,
    iconSize: [30, 30], // size of the icon
  })
  const SevenIcon = new L.Icon({
    iconUrl: Seven,
    iconSize: [30, 30], // size of the icon
  })
  const EightIcon = new L.Icon({
    iconUrl: Eight,
    iconSize: [30, 30], // size of the icon
  })
  const NineIcon = new L.Icon({
    iconUrl: Nine,
    iconSize: [30, 30], // size of the icon
  })
  const TenIcon = new L.Icon({
    iconUrl: Ten,
    iconSize: [30, 30], // size of the icon
  })

  switch (val) {
    case 1: return OneIcon
    case 2: return TwoIcon
    case 3: return ThreeIcon
    case 4: return FourIcon
    case 5: return FiveIcon
    case 6: return SixIcon
    case 7: return SevenIcon
    case 8: return EightIcon
    case 9: return NineIcon
    case 10: return TenIcon
    default: return OneIcon
  }
}
